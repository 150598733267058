import { Howl, Howler } from 'howler';

export const fetchSound = async (path) =>  {
    return new Promise((resolve, reject) => {
        console.log('path = ' + path);
        var sound = new Howl({
            src: [path]
          });
        sound.onload = resolve(sound);
        sound.onloaderror = reject(`cant load ${path}`);
    });
}