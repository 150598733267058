
import React from 'react';
import ReactHtmlParser from 'react-html-parser';


export const OptionButton = (props) => {
    const o = props.option;
    const onButtonClick = props.onClick;
    let buttonClassName = "optionButton appears";
    let image = <></>
    let text = ReactHtmlParser(o.title.replace(/\\n/g, '<br>'));
    let buttonText = text;

    if (o.title == '>>>') {
        buttonClassName = "adSlideButton appears";
        image = <img src="data/ui/play.svg" className="adButtonImage"></img>
        buttonText = <div className="adButtonText">Далее</div>
    }
    if (o.params.rewarded_ad) {
        buttonClassName = "adSlideButton appears";
        image = <img src="data/ui/adv.svg" className="adButtonImage"></img>
        buttonText = <div className="adButtonText">
            {text}
        </div>
    }
    if (o.params.mainmenu) {
        buttonClassName = "adSlideButton appears";
        image = <img src="data/ui/mainmenu.svg" className="adButtonImage"></img>
        buttonText = <div className="adButtonText">
            {text}
        </div>
    }

    return <button
        className={buttonClassName}
        onClick={() => onButtonClick(o)}>
        {image}
        {buttonText}
    </button>

}