import { configureStore } from '@reduxjs/toolkit';
import storyReducer from '../features/story/storySlice';
import settingsReducer from '../features/settings/settingsSlice';
import soundsReducer from '../features/sounds/soundsSlice';

export default configureStore({
  reducer: {
    story: storyReducer,
    settings: settingsReducer,
    sounds: soundsReducer,
  },
});