import React, { useRef, useEffect } from 'react';
import * as lottie from 'lottie-web/build/player/lottie_svg';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import {  selectSettings } from '../../settings/settingsSlice';

const imgRegex = /.*\.(?:jpg|gif|png|svg)/g
const dotLottie = /.*\.(?:lottie)/g

export const SlideContent = (props) => {
    const slideData = props.slideData;
    const assetsProvider = props.assetsProvider;
    const textType = slideData.textType;
    const textSpeach = slideData.textSpeach;
    const textOverlay = slideData.textOverlay;
    const slideKey = slideData.slideKey;
    const lottieCanvas = useRef(null);
    const lottieName = `data/${slideData.imageNameWOext}.lottie`;
    const lottieData = assetsProvider(lottieName);
    let imageUrl = ""; //FIXME: fallback to jpg if no lottie found (refactor before release)

    const settings = useSelector(selectSettings);

    if (lottieData) {
        imageUrl =  `data/${slideData.imageNameWOext}.lottie`;
    } else if (slideData.imageNameWOext !== "") {
        imageUrl =  `data/${slideData.imageNameWOext}.jpg`;
    }

    let sound = slideData.lottieOptions.sound;

    useEffect(() => {
        if (sound && settings.sound) {
            let audio = assetsProvider(`data/sound/${sound}.mp3`);
            //  check why it could be undefined 
            if (audio !== undefined) {
                audio.play();
            }
        }
    }, [sound]);

    useEffect(() => {
        if (lottieData) {
            let a = lottie.loadAnimation({
                container: lottieCanvas.current, // the dom element
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: lottieData, // the animation data
                rendererSettings: {
                    scaleMode: 'noScale',
                    clearCanvas: true,
                    progressiveLoad: true,
                    hideOnTransparent: false
                }
            });
            let loopN = (slideData.lottieOptions.loop) ? slideData.lottieOptions.loop : 0;
            a.playSegments([[loopN, a.totalFrames]], false);
            return () => {
                a.stop();
                a.destroy();
            }
        }
    }, [imageUrl, slideData.lottieData]);

    let graphicsContent = <p>{textOverlay}</p>
    if (imageUrl !== "") {
        if (imageUrl.match(imgRegex)) {
            graphicsContent = <img src={imageUrl} alt={imageUrl} className="centerImage" />
        }
        else if (imageUrl.match(dotLottie)) {
            graphicsContent =
                <div key="graphics" ref={lottieCanvas} className="centerImage">
                </div>
        }
    }

    let textContent = "";
    if (textType >= 0) {
        textContent = <div key="text" className={"SpeechContainer" + textType.toString()}  >
            <div className={"Speech" + textType.toString()} >
                <p>{ReactHtmlParser(textSpeach )}</p>
            </div>
        </div>
    }

    return <>{graphicsContent}  {textContent}</>
}