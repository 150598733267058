

import './TopBarMenu.css';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { toggleMusic, toggleSound, selectSettings } from './settings/settingsSlice';
import { soundsSlice }  from './sounds/soundsSlice';

export const TopBarMenu = () => {

  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();
  const { playMusic, pauseMusic, playSound } = soundsSlice.actions;

  let [isFullscreen, setFullscreen] = useState(false);

  function goFull() {
    document.documentElement.requestFullscreen();
    setFullscreen(true);
  }

  function exitFull() {
    document.exitFullscreen();
    setFullscreen(false);
  }

  function soundOn() {
    dispatch(playSound("on"));
 //   document.getElementById('buttonSoundOn').play();
    dispatch(toggleSound(true));
  }


  function soundOff() {
    dispatch(playSound("off"));
    //document.getElementById('buttonSoundOff').play();
    dispatch(toggleSound(false));
  }

  function musicOn() {
   // document.getElementById('musicPlayer').play();
    dispatch(playMusic("music"));
    dispatch(toggleMusic(true));
  }


  function musicOff() {
    dispatch(pauseMusic("music"));  
   // document.getElementById('musicPlayer').pause();
    dispatch(toggleMusic(false));
  }

  return (

      <div className="settingsMenu">
        {settings.sound &&
          <button className="settingsButton" onClick={soundOff} >
            <img src="data/ui/sound-on.svg" width="100%" height="100%"></img>
          </button>
        }
        {!settings.sound &&
          <button className="settingsButton" onClick={soundOn} >
            <img src="data/ui/sound-off.svg" width="100%" height="100%"></img>
          </button>
        }

        {settings.music &&
          <button className="settingsButton" onClick={musicOff} >
            <img src="data/ui/music-on.svg" width="100%" height="100%"></img>
          </button>
        }
        {!settings.music &&
          <button className="settingsButton" onClick={musicOn} >
            <img src="data/ui/music-off.svg" width="100%" height="100%"></img>
          </button>
        }

        {document.fullscreenEnabled && !isFullscreen &&
          <button className="settingsButton" onClick={goFull} >
            <img src="data/ui/fullscreen.svg" width="100%" height="100%"></img>
          </button>
        }

        {isFullscreen &&
          <button className="settingsButton" onClick={exitFull} >
            <img src="data/ui/exit-fullscreen.svg" width="100%" height="100%"></img>
          </button>
        }
      </div>
  );
}