import React, { useEffect, useState } from 'react';
import {  selectSettings } from '../../settings/settingsSlice';
import { soundsSlice }  from '../../sounds/soundsSlice';
import { useSelector, useDispatch } from 'react-redux';

export const RewardedAd = (props) => {
    const onSuccess = props.onSuccess;
    const onError = props.onError;
    const settings = useSelector(selectSettings);
    const dispatch = useDispatch();

    const { playMusic, pauseMusic } = soundsSlice.actions;


    useEffect(() => {
        window.ysdk.adv.showRewardedVideo({
            callbacks: {
                onOpen: () => {
                    // document.getElementById('musicPlayer').pause();
                    dispatch(pauseMusic("music"));
                    console.log('Video ad open.');
                },
                onRewarded: () => {
                    console.log('Rewarded!');
                },
                onClose: () => {
                    onSuccess(true);
                    if (settings.music) {
                        dispatch(playMusic("music"));
                       // document.getElementById('musicPlayer').play();
                    };
                    
                }, 
                onError: (e) => {
                    onError(e);
                    console.log('Error while open video ad:', e);
                }
            } 
        });
    }, [false]);

    return <></>;
}