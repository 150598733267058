import * as JSZip from 'jszip';

export const fetchDotLottie = async (path) =>  {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', path, true);
      xhr.responseType = 'arraybuffer';
      xhr.send();
      xhr.onreadystatechange = function () {
        if (xhr.readyState !== 4) return;

        if (xhr.status === 404) {
          reject("not_found");
        } 
        else if (xhr.status === 200) {
          JSZip.loadAsync(xhr.response)
            .then((zip) => {
              zip.file('manifest.json').async('string')
                .then((manifestFile) => {
                  const manifest = JSON.parse(manifestFile);
  
                  if (!('animations' in manifest)) {
                    throw new Error('Manifest not found');
                  }
  
                  if (manifest.animations.length === 0) {
                    throw new Error('No animations listed in the manifest');
                  }
  
                  const defaultLottie = manifest.animations[0];
  
                  zip.file(`animations/${defaultLottie.id}.json`).async('string')
                    .then((lottieFile) => {
                      const lottieJson = JSON.parse(lottieFile);
  
                      if ('assets' in lottieJson) {
                        Promise.all(
                          lottieJson.assets.map((asset) => {
                            if (!asset.p) {
                              return 0;
                            }
  
                            return new Promise((resolveAsset) => {
                              zip.file(`images/${asset.p}`).async('base64').then((assetB64) => {
                                asset.p = 'data:;base64,' + assetB64;
                                asset.e = 1;
  
                                resolveAsset();
                              });
                            });
                          })
                        ).then(() => {
                          resolve(lottieJson);
                        });
                      }
                    });
                });
            })
            .catch((err) => {
              reject(err);
            });
        }
      };
    });
  }