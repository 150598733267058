import './MainMenu.css';
import React, { useState } from 'react';
import { restartGame } from './story/storySlice';
import { useDispatch } from 'react-redux';


export const MainMenu = ({ toggleFunc }) => {

    let [firstStart, setFirstStart] = useState(false);
    const dispatch = useDispatch();

    function newGame() {
        dispatch(restartGame("data/story.json", "start"));
        toggleFunc();
    }

    return (
        <div className="mainMenuHoleScreen">
            <div className="mainMenuBorder">
                <div className="mainMenuContainer">
                    
                    <div className="mainMenuHeaderConnainer">                        
                        <img id="menu-header" className="mainMenuHeader" src="data/ui/fabula-header.svg" />
                        <div className="mainMenuSubHeader">
                            клевые сюжетные квесты
                        </div>
                    </div>

                    
                        
                        <div className="mainMenuEpName">
                                Космический мастер Фикс. S1 E1
                        </div>
                        <div className="episodeContainer">
                            <img id="icon" className="episodeIcon" src="data/ui/icon_fix_1_1.svg" />
                            <div className="mainMenuEpDesc">
                                Пилотная серия о Фиксе, мастере на все руки, к которому приходят заказы со всей обитаемой Вселенной.
                            </div>
                            <div className="episodeButtonContainer">
                                <button className="mainMenuButton1" onClick={toggleFunc}> Продолжить</button>
                                <button className="mainMenuButton2" onClick={newGame}> Начать сначала</button>
                            </div>
                        </div>
                        
                        <div className="mainMenuEpName">
                            Скоро появятся новые серии...
                        </div>
                        <div className="lastEpisodeContainer">
                            <img id="iconComingSoon" className="episodeIconLast" src="data/ui/coming-soon.svg" />
                            <div className="mainMenuEpDesc">
                                Вы можете ускорить их появление, если оставите положительный отзыв!
                            </div>
                        </div>

                   

                </div>
            </div>
        </div>
    )
};