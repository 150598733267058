import { createSlice } from '@reduxjs/toolkit';
import { fetchDotLottie } from './dotLottie/loader';
import { fetchSound } from '../sounds/loader';
import { storage as localStorage} from '../db/storage';

let assetsCache = {};

const getNodeStub = () => {return {allOptions:[{title:""}], leftOptions:[], rightOptions:[]}};

export const storySlice = createSlice({
  name: 'story',
  initialState: {
    loaded: false,
    transition:null,
    data: null,
    currentNode: getNodeStub(),
    flags:[],
    assetsLoading:{},
    assetsLoaded:{}
  },

  reducers: {

    clearState: (state) => {
      state.loaded = false;
      state.transition = null;
      state.data = null;
      state.currentNode = getNodeStub();
      state.flags = [];
      state.assetsLoaded = {};
      state.assetsLoading = {};

      localStorage.removeItem("flags");
      localStorage.removeItem("lastSlide");
      assetsCache = {};
    },

    storyLoaded: (state, action) => {
      state.data = action.payload;
      if (localStorage.getItem("flags")) 
        state.flags = JSON.parse(localStorage.getItem("flags"));
    },

    stateInited: (state) => {
      state.loaded = true;
      if (state.transition)
        state.transition.phase = "hidden";
    },

    exitSlide: (state, action) => {
      const currentNode = state.currentNode;
      let nextNode = action.payload;
      const stitches = state.data.data.stitches;
      if (!stitches.hasOwnProperty(nextNode))
        nextNode = state.data.data.initial;

      const currentSlideData = currentNode;
      const nextSlideData = getSlideData(nextNode, state);
      const currentImage = (currentSlideData) ? currentSlideData.imageName : null;

      const changed = currentImage !== nextSlideData.imageName;
      
      state.transition = {
        phase: 'fadeout',
        current:currentSlideData,
        next:nextSlideData
      };

      if (!changed) { // || nextSlideData.imageName === "") {
        onSlideEntered(state);
      }
    },

    slideExited: (state, action) => {
      state.transition.phase = "hidden";
      const slide = state.transition.next;
      if (slide.loaded)
        state.transition.phase = "fadein";
    },
    assetLoadStart: (state, action) => {
      state.transition.next.loading = true;
    },

    assetLoadEnd: (state, action) => {
      const [asset] = action.payload
      delete state.assetsLoading[asset.url];
      state.assetsLoaded[asset.url] = 1;
      const loadingSlide = state.transition.next;

      state.transition.next.loaded = checkLoaded(loadingSlide, state)
      state.transition.next.loading = !state.transition.next.loaded;
      if (state.transition.next.loaded) {
        if (state.transition.phase === "hidden") {
          state.transition.phase = "fadein";
        }
      }
    },

    lottieNotFound: (state, action) => {
      const [asset] = action.payload;
      delete state.assetsLoading[asset.url];
      state.assetsLoaded[asset.url] = 1; //FIXME: fallback to jpg if no lottie found (refactor before release)

      const loadingSlide = state.transition.next;
      state.transition.next.loaded = checkLoaded(loadingSlide, state)
      state.transition.next.loading = !state.transition.next.loaded;
      if (state.transition.next.loaded) {
        if (state.transition.phase === "hidden") {
          state.transition.phase = "fadein";
        }
      }
    },

    slideEntered: (state, action) => {
      onSlideEntered(state);
    }
  },
});

const checkLoaded = (slide, state) => {
  let result = true;
  for (let a of slide.assets) {
    if (!state.assetsLoaded.hasOwnProperty(a.url)) {
      result = false;
    }
  }
  return result;
}



const onSlideEntered = (state) => {
  if (state.transition) {
    state.currentNode = state.transition.next;
  }
  const slide = state.currentNode;

  slide.flags.forEach(flag => {
      if (state.flags.indexOf(flag) < 0) {
        state.flags.push(flag);
      }
    });
  
  if (slide.isKey)
  {
    localStorage.setItem("lastSlide", slide.slideKey);
    localStorage.setItem("flags", JSON.stringify(state.flags));
  }
  state.transition = null;
}

export const {clearState, storyLoaded, stateInited, exitSlide, lottieNotFound, assetLoadStart, assetLoadEnd } = storySlice.actions;

export const restartGame = (url, startNode) => async (dispatch) => {
  dispatch(clearState());
  dispatch(fetchStory(url, startNode));
}

export const fetchStory =  (url, startNode) => async (dispatch, getState) => {  
  const response = await fetch(url);
  const parsed = await response.json();

  await dispatch(storyLoaded(parsed, startNode));
  if (startNode === "start") {
    const state = getState();
    dispatch(exitSlide(state.story.data.data.initial));
  } else {
    dispatch(exitSlide(startNode));
  }
  dispatch(stateInited());
};

export const fetchAssets = slideData => async (dispatch, getState) => {
  const assets = slideData.assets;
  const state = getState();

  const assetsToLoad = [];
  assets.forEach((asset) => {
    if (!state.story.assetsLoaded.hasOwnProperty(asset.url))
      dispatch(assetLoadStart(asset.url));
      assetsToLoad.push(asset);
  });

  for (const asset of assets) {
    if (asset.type == "lottie") {
      try {
        const dotLottieData = await fetchDotLottie(asset.url);
        assetsCache[asset.url] = dotLottieData;
        dispatch(assetLoadEnd([asset]));
      } catch(e) {
        console.log(e);
        dispatch(lottieNotFound([asset]));
      }
    } else if (asset.type == "sound") {
      const soundData = await fetchSound(asset.url);
      assetsCache[asset.url] = soundData;
      dispatch(assetLoadEnd([asset]));
    }
  }
};

const getSlideData = (slideId, state) => {
  let slideData = {
    slideKey:"",
    slideBack:{type:"none"},
    textOverlay:"none",
    textSpeach:"",
    textType: -1,
    imageName:"",
    imageNameWOext: "",
    imageName2:"",
    lottieOptions:{trans_color: "white"}, // transition background color
    flags:[],
    leftOptions:[],
    rightOptions:[],
    allOptions:[],
    assets:[],
    loaded:true,
    loading:false,
    ad:false,
    isKey:false
  };

  console.log('getSlideData: ' + slideId);
  slideData.slideKey = slideId;

  const stitches = state.data.data.stitches;
  const slide = stitches[slideId];

  let options = slideData.rightOptions;
  let addOption = option => {
    //if (state.transition != null) return;

    slideData.allOptions.push(option);
    options.push(option);
    options = (options === slideData.leftOptions) ? slideData.rightOptions : slideData.leftOptions;
  }
  slide.content.forEach(v => {
    if (typeof v === "string") {
      let i = v.indexOf("[");
      let i2 = v.indexOf("]");

      if ( i>= 0 && i2 > 0)  {
        let graphics = v.substring(i, i2 + 1);
        let graphicsParsed = JSON.parse(graphics);

        slideData.imageName = graphicsParsed[0];
        if (graphicsParsed[1]) {
          slideData.lottieOptions = graphicsParsed[1];
          if (slideData.lottieOptions.trans_color == undefined)
          {
            slideData.lottieOptions.trans_color = "white";
          }
          if (slideData.lottieOptions.sound) {
            slideData.assets.push({url:`data/sound/${slideData.lottieOptions.sound}.mp3`, type:"sound"});
          }
        }

        [slideData.imageNameWOext] = slideData.imageName.split(".");

        slideData.assets.push({url:`data/${slideData.imageNameWOext}.lottie`, type:"lottie"});
 
        let i3 = v.indexOf("[", i2+1);
        let i4 = v.indexOf("]", i2+1);
        if (i3>= 0 && i4 > 0)  {
          slideData.imageName2 = v.substring(i3 + 1, i4);
        }
      }

      const patterns = ["-- ", "// ", ">> ", "** "];
      let textStartIndex = -1;
      for (let i=0; i<patterns.length; i++) {
        textStartIndex = v.indexOf(patterns[i]);
        if (textStartIndex >= 0) {
          slideData.textType = i;
          break;
        }
      }

      if (v.startsWith("Ad"))
        slideData.ad = true;
      
      if (textStartIndex !== -1) {
        slideData.textSpeach = v.substring(textStartIndex+3).replace(/\\n/g,'<br>');
        slideData.textOverlay =  v.substring(0,textStartIndex);
      }
      else {
        slideData.textOverlay =  v;
      }
    } else if (v.flagName) {
      slideData.flags.push(v.flagName);
    } else if (v.pageLabel) {
      slideData.isKey = true;
    } else if (v.divert) {
      addOption({title: ">>>", link:v.divert, sound: "", params: {}});
    } else if (v.option) {
      let conditionPass = true;
      
      if (v.ifConditions != null) {
        v.ifConditions.forEach((c => {
          if (state.flags.indexOf(c.ifCondition) < 0) {
            conditionPass = false;
          }
        }));
      }

      if (v.notIfConditions != null) {
        v.notIfConditions.forEach((c => {
          if (state.flags.indexOf(c.notIfCondition) >= 0) {
            conditionPass = false;
          }
        }));
      }

      if (conditionPass) {
        let i = v.option.indexOf("[");
        let i2 = v.option.indexOf("]");
        
        let optionName = v.option;
        let soundName = "";
        let optionParams = { mainmenu: false};
        if ( i>= 0 && i2 > 0)  {
          let strParams = v.option.substring(i, i2 + 1);
          let paramsParsed = JSON.parse(strParams);
          soundName = paramsParsed[0];
          optionName = optionName.substring(i2+1);          
          if (paramsParsed[1]) {
            optionParams = paramsParsed[1];
          }
        }
        addOption({title: optionName, link:v.linkPath, sound: soundName, params: optionParams});
      }
    }
  });
  slideData.loaded = checkLoaded(slideData, state);
  return slideData;
}

export const selectStory = state => state.story;
export const selectCurrentSlide = state => {
  return state.story.currentNode;
}

export const selectTransition = state => {
  return state.story.transition;
}

export const getAsset = fileName => {
  return assetsCache[fileName];
}

export default storySlice.reducer;
