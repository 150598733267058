
import { createSlice } from '@reduxjs/toolkit';
import { Howl, Howler } from 'howler';
import { fetchSound } from './loader';



let musicArray = {};
let soundArray = {};

function InitSound() {
    // Set the name of the hidden property and the change event for visibility
    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    // If the page is hidden, pause the video;
    // if the page is shown, play the video
    function handleVisibilityChange() {
        if (document[hidden]) {
            Howler.mute(true);
        } else {
            Howler.mute(false);
        }
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
        console.log("This game requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
        // Handle page visibility change
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }
}


export const soundsSlice = createSlice({
    name: 'sounds',
    initialState: {
        init: false,
    },

    reducers: {
        playMusic: (state, action) => {
            if (!state.init) {
                InitSound();
                state.init = true;
            }
            const fileName = action.payload;
            var sound = musicArray[fileName];
            if (sound === undefined) {
                let pathMP3 = 'data/sound/' + fileName + '.mp3';
                let pathWEBM = 'data/sound/' + fileName + '.webm';

                sound = new Howl({
                    src: [pathMP3, pathWEBM]
                });
                console.log("fileName = " + fileName);
                sound.once('load', () => {
                    sound.play();
                    musicArray[fileName] = sound;
                });
                sound.once('loaderror', () => {
                    console.log("can't load music: " + fileName);
                });
            }
            else {
                if (!sound.playing()) sound.play();
            }
        },
        playSound: (state, action) => {
            if (!state.init) {
                InitSound();
                state.init = true;
            }
            const fileName = action.payload;
            var sound = soundArray[fileName];
            if (sound === undefined) {
                sound = new Howl({
                    src: ['data/sound/' + fileName + '.mp3']
                });
                sound.once('load', () => {
                    sound.play();
                    soundArray[fileName] = sound;
                });
                sound.once('loaderror', () => {
                    console.log("can't load music: " + fileName);
                });
            }
            else {
                if (!sound.playing()) sound.play();
            }

        },

        pauseMusic: (state, action) => {
            const fileName = action.payload;
            var sound = musicArray[fileName];
            if (sound !== undefined) {
                sound.pause();
            }
        },
        stopSound: (state, action) => {
        }
    }
});

export const { playMusic, loadSound, playSound, pauseSound, stopSound } = soundsSlice.actions;



export default soundsSlice.reducer;