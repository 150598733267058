import React, { useEffect, useState } from 'react';
import {  selectSettings } from '../../settings/settingsSlice';
import { soundsSlice }  from '../../sounds/soundsSlice';
import { useSelector, useDispatch } from 'react-redux';

export const RewardedAd = (props) => {
    const onSuccess = props.onSuccess;
    const onError = props.onError;
    const settings = useSelector(selectSettings);
    const dispatch = useDispatch();

    const { playMusic, pauseMusic } = soundsSlice.actions;

    useEffect(() => {
        const adman = window.vk_ads;

        adman.onStarted(function () {
            dispatch(pauseMusic("music"));
            console.log('Video ad open.');
        });
        adman.onCompleted(function() {
            onSuccess(true);
            if (settings.music) {
                dispatch(playMusic("music"));
            };
        });
        adman.onSkipped(function() {
            onError('skiped');
        });
        adman.onClicked(function() {
            onSuccess(true);
        });
        adman.start('preroll');

    }, [false]);

    return <></>;
}