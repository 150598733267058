import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAssets, getAsset, getLottie, selectCurrentSlide, selectTransition, storySlice } from './storySlice';
import { SlideContent } from './view/SlideContent';
import { OptionButton } from './view/OptionButton';
import {  selectSettings } from '../settings/settingsSlice';

import { Transition } from 'react-transition-group';
import { AdWatterfall } from '../ad/AdWatterfall';

import { soundsSlice }  from '../sounds/soundsSlice';


export const StoryView = ({ toggleMainMenu }) => {

    const { playMusic, playSound } = soundsSlice.actions;

    const transitionData = useSelector(selectTransition);
    let current = useSelector(selectCurrentSlide);
    const dispatch = useDispatch();
    
    let  [ slideToReturnFromAd, setSlideToReturnFromAd ] = useState(null);

    const settings = useSelector(selectSettings);

    let next = null;
    let phase = "fadein";
    if (transitionData != null) {
        current = transitionData.current;
        next = transitionData.next;
        if (transitionData.phase === "fadein") {
            current = next;
        }
        phase = transitionData.phase;
    }

    const { exitSlide, slideExited, slideEntered } = storySlice.actions;
    const nextUrl = next && next.imageNameWOext !== "" ? `data/${next.imageNameWOext}.lottie` : "";

    useEffect(() => {
        if (next) {
            if (!next.loaded && !next.loading) {
                dispatch(fetchAssets(next));
            }
        }
    }, [nextUrl]);

    const enterDuration = 100;
    const exitDuration = 500;

    const transitionStyles = {
        entering: { transition: `opacity ${enterDuration}ms ease-out`, opacity: 1 },
        entered: { transition: `opacity ${enterDuration}ms ease-out`, opacity: 1 },
        exiting: { transition: `opacity ${exitDuration}ms ease-in`, opacity: 0 },
        exited: { transition: `opacity ${exitDuration}ms ease-in`, opacity: 0 },
    };

    //console.log(`phase: ${phase} ${Date.now()}`);

    const phaseIn = (phase === "fadein");

    let transitionColor = (current && current.lottieOptions) ? current.lottieOptions.trans_color : "white";
    let backgroundColor = { "backgroundColor": transitionColor };

    function onButtonClick(option) {
        if (settings.sound) {
            if (option.sound != "") {
                dispatch(playSound(option.sound));
               // var sound = new Audio(`data/sound/${option.sound}.mp3`);
               // sound.play();
            } 
            else dispatch(playSound("tap"));
            //document.getElementById('optionSound').play();
        }
 
        if (option.params.rewarded_ad)
        {
            // before to switch on rewarded ad
            setSlideToReturnFromAd(current.slideKey);
        }
        if (option.params.mainmenu)
        {
            toggleMainMenu();
            return;
        }
        if (settings.music)
        {
            dispatch(playMusic("music"));
           // document.getElementById('musicPlayer').play();
        }
        dispatch(exitSlide(option.link));
    }

    let leftButtons = <div className="leftColumn">
        {current.leftOptions.map((o) => <OptionButton  key={`${o.title}_${o.link}`} option= {o} onClick = {onButtonClick}></OptionButton> ) }
    </div>

    let rightButtons = <div className="rightColumn">
        {current.rightOptions.map((o) => <OptionButton    key={`${o.title}_${o.link}`}  option= {o} onClick = {onButtonClick}></OptionButton> ) }
    </div>


    let allButtons = <>
        {current.allOptions.map((o) => <OptionButton  key={`${o.title}_${o.link}`} option= {o} onClick = {onButtonClick}></OptionButton> ) }
    </>


    let resultButtons = "";
     const inTransition = (transitionData != null);
    const showAd = (!(phase === "hidden") && current.ad);
    const hideButtons = (inTransition || showAd);

    if (window.innerHeight < window.innerWidth) {
        resultButtons = (<>
            {leftButtons}
            <div className="emptyCenterColumn"></div>
            {rightButtons}
        </>
        );
    }
    else {
        resultButtons = (<>
            <div className="emptyTopBar"> </div>
            <div className="emptyCenterColumn"> </div>
            <div className="buttonContainer">
                {allButtons}
            </div>
        </>
        );
    }

    let result =
        <div className="slide">
            <div className="centerColumn" style={backgroundColor}>
                <Transition in={phaseIn} timeout={{ exit: exitDuration, enter: enterDuration }}
                    onEnter={() => {
                        //console.log(`onEnter start: ${Date.now()}`);
                    }}
                    onEntered={() => {
                        //console.log(`onEnter end: ${Date.now()}`);
                        dispatch(slideEntered());
                    }}
                    onExit={() => {
                        //console.log(`onExit start: ${Date.now()}`)
                    }}
                    onExited={() => {
                        //console.log(`onExit end: ${Date.now()}`);
                        dispatch(slideExited());
                    }}>
                    {state => (
                        (showAd) ? 
                        <AdWatterfall 
                            onSuccess={wasShown => {
                                console.log(`ad shown: ${wasShown}`); 
                                if (wasShown) {
                                    dispatch(exitSlide(current.allOptions[0].link));
                                } else {
                                    dispatch(exitSlide(slideToReturnFromAd));
                                }
                            }}
                            onError={error => {
                                console.log(`ad error: ${error}`); 
                                dispatch(exitSlide(current.allOptions[0].link));
                            }}
                        />

                        :
                            (phase === "hidden") ?
                            
                            <div className="contentContainer">
                                <img id="loading" className="loagingImg" src="data/ui/loading.svg" />
                            </div>

                            :
                            
                            <div className="contentContainer" style={{ ...transitionStyles[state] }}>
                                <SlideContent slideData={current} assetsProvider={getAsset} transitionPhase={phase} />
                            </div>
                            
                )}
                </Transition>
            </div>
            <div className="row" >
                {hideButtons || resultButtons}
            </div>
        </div>;

    return result;
}
