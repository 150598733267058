import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { StoryView } from './features/story/StoryView';
import { TopBarMenu } from './features/TopBarMenu';
import { MainMenu } from './features/MainMenu';
import { fetchStory, selectStory } from './features/story/storySlice';
import { appLoaded } from './features/settings/settingsSlice';
import { soundsSlice }  from './features/sounds/soundsSlice';
import { storage as localStorage} from './features/db/storage';
import 'url-search-params-polyfill';



function App() {

  const { loadSound, playSound, pauseSound, stopSound } = soundsSlice.actions;

  const story = useSelector(selectStory);
  const dispatch = useDispatch();

  let [somevar, setVar] = useState(false);
  let [mainMenu, setMainMenu] = useState(false);

  function toggleMainMenu() {
    setMainMenu(!mainMenu);
  }

  useLayoutEffect(() => {
    function updateSize() {
      setVar(!somevar);
      console.log('updateSize');
    }
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    let startNode = "start";
    if (searchParams.has("label"))
      startNode = searchParams.get("label");
    else if (localStorage.getItem("lastSlide"))
      startNode = localStorage.getItem("lastSlide");

    dispatch(appLoaded());
    dispatch(fetchStory("data/story.json", startNode));//"http://www.inklewriter.com/stories/6456.json"));
  }, [dispatch]);
   
  /*   
   <audio id="musicPlayer" src="data/sound/music.mp3" ></audio> 
   <audio id="buttonSoundOn" src="data/sound/on.mp3" ></audio>
   <audio id="buttonSoundOff" src="data/sound/off.mp3" ></audio>
   <audio id="optionSound" src="data/sound/tap.mp3" ></audio>
*/
  return (
    <div className="App">


      {!story.loaded &&
        <div className="InitialLoaging" >
          <img id="loading" className="loagingImg" src="data/ui/loading.svg" />
        </div>
      }

      {story.loaded &&
        <div>
          <div id="Top Menu bar">
            {!mainMenu &&
            <div>
              <button className="mainmenuButton" onClick={toggleMainMenu} >
                <img src="data/ui/mainmenu.svg" width="100%" height="100%"></img>
              </button>
              <TopBarMenu></TopBarMenu>
            </div>
            }
            
          </div>
          {mainMenu && <MainMenu toggleFunc = {toggleMainMenu}> </MainMenu>}
          {!mainMenu && <StoryView toggleMainMenu = {toggleMainMenu}></StoryView>}

        </div>
      }
    </div>
  );
}

export default App;
