import { createSlice } from '@reduxjs/toolkit';
import { storage as localStorage} from '../db/storage';

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
      settings:{music:true, sound:true},
    },
  
    reducers: {
      appLoaded: (state) => {
          const settings = localStorage.getItem("settings");
          if (settings)
            state.settings = JSON.parse(settings);
      },

      toggleMusic: (state, action) => {
        const enabled = action.payload;
        state.settings.music = enabled;
        localStorage.setItem("settings", JSON.stringify(state.settings));
      },
  
      toggleSound: (state, action) => {
        const enabled = action.payload;
        state.settings.sound = enabled;
        localStorage.setItem("settings", JSON.stringify(state.settings));
      }
    }
});

export const { appLoaded, toggleMusic, toggleSound } = settingsSlice.actions;

export const selectSettings = state => { 
    return state.settings.settings;
}

export default settingsSlice.reducer;