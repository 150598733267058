import React, {useRef, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { InterstitialAd } from './yaGames/InterstitialAd';
import { RewardedAd as YgRewardedAd} from './yaGames/RewardedAd';
import { RewardedAd as VkRewardedAd} from './vk/RewardedAd';

export const AdWatterfall = (props) => {
/*    const slideBeforeAd = props.slideBeforeAd;
    const slideAfterAd = props.slideAfterAd;
    const dispatch = useDispatch(); */
    
    const noAd = useRef(null);
    useEffect(() => {
        if (noAd.current)
            setTimeout(() => props.onSuccess(true), 2000);
    }, [false]);

    let result =<div ref={noAd} >
        <p>No AD provider</p>
    </div>;

    if (window.ysdk)
        result = <YgRewardedAd onSuccess={props.onSuccess} onError={props.onError} />
    else if (window.vk_ads)
        result = <VkRewardedAd onSuccess={props.onSuccess} onError={props.onError} />
     //   result = <InterstitialAd onSuccess={props.onSuccess} onError={props.onError} />

    return result;
}