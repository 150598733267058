import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './app/store';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: "https://6f601fc53f0343fa9c7f8f8235911acd@o523696.ingest.sentry.io/5635957",
  integrations: [new Integrations.BrowserTracing()],
  release: "fabulafun@" + process.env.REACT_APP_RELEASE,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
      <App />
     </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
