export const storage = {
    getItem : (key) => {
        let result = undefined;
        try { result = localStorage.getItem(key); } catch(e) {}
        return result;
    },

    setItem : (key) => {
        try { localStorage.setItem(key); } catch(e) {}
    },

    removeItem : (key) => {
        try { localStorage.removeItem(key); } catch(e) {}
    }
}